@import '~antd/dist/antd.css';
body {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ff937e;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff937e;
    border-color: #ff937e;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #ff937e;
  }
  .ant-checkbox-wrapper {
    span {
      font-family: Kanit;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #393f4d;
    }
  }
  .ant-picker-content th,
  .ant-picker-content td,
  .ant-select-item {
    font-weight: 300;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-input-password {
    background: #f2f6fb;
    border-radius: 10px;
    border-color: #f2f6fb;
    .ant-input {
      height: 40px;
      border-radius: unset;
      border: none;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 50px;
    border-radius: 10px;
    // background: #f2f6fb;
  }

  .ant-input {
    height: 50px;
    background: #f2f6fb;
    border-radius: 10px;
    font-family: Kanit;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    border-color: #f2f6fb;
  }
  .ant-picker {
    height: 50px;
    width: 100%;
    border-radius: 10px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    display: flex;
    align-items: center;
    // background: #f2f6fb;
    border-radius: 10px;
  }

  .ant-input:hover,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #b2d6f8;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input:focus,
  .ant-input-focused {
    border-color: #b2d6f8;
    box-shadow: 0 0 0 2px rgba(178, 214, 248, 0.2);
  }

  .ant-form-item-required {
    position: relative;
    &::before {
      position: absolute;
      right: -14px;
      top: 2px;
    }
  }
  .ant-form-item-explain.ant-form-item-explain-error,
  .ant-form-item-label {
    font-weight: 300;
  }

  .ant-modal-body {
    .wrap-croup-image {
      .group-button {
        margin-top: 20px;
        text-align: center;
        > button {
          margin: 0 10px;
        }
      }
    }
  }
  .ant-radio-wrapper {
    span {
      font-family: Kanit;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
    }
  }
  .ant-radio-checked::after,
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #ee6538;
  }
  .ant-radio-inner::after {
    background-color: #ee6538;
  }

  .ant-modal-content {
    background: #ffffff;
    border-radius: 10px;
  }
  .ant-modal-confirm-body {
    > .anticon {
      display: none;
    }
  }
  .ant-modal-confirm-body
    > .anticon
    + .ant-modal-confirm-title
    + .ant-modal-confirm-content {
    margin: 0;
  }
  .ant-modal-confirm .ant-modal-confirm-btns {
    float: unset;
    display: flex;
    justify-content: center;
    .ant-btn {
      background: #ffffff;
      border: 1px solid #ff7a44;
      color: #ff7a44;
      box-sizing: border-box;
      font-family: Kanit;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 30px;
      height: auto;
      min-width: 100px;
    }
    .ant-btn-primary {
      background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
      color: #fff;
      box-shadow: 0px 2px 10px #ffe0c2;
      border-radius: 30px;
    }
  }
  .ant-row {
    margin-left: -12px;
    margin-right: -12px;
    .ant-col {
      padding: 0 12px;
    }
  }
  .ant-modal.main-modal {
    .ant-modal-close {
      display: none;
    }
    .ant-modal-content {
      border-radius: 20px;
    }
    .ant-modal-header {
      font-family: Kanit;
      background: #ff937e;
      border-radius: 20px 20px 0px 0px;
      overflow: hidden;
      padding: 30px 24px;
      text-align: center;
      .ant-modal-title {
        color: #fff;
        font-weight: 500;
        font-size: 28px;
      }
    }
  }
  .ant-collapse {
    margin-bottom: 16px;
    border: none;

    > .ant-collapse-item {
      border: none;
      &.ant-collapse-item-active {
        border: none;
        border-radius: 0px;
        .ant-collapse-header {
          background-color: #ff937e;
          color: #fff;
        }
      }
    }
    .ant-collapse-content {
      background: #ffffff;
      border: 1px solid #ff937e;
      box-sizing: border-box;
      border-radius: 10px;
      margin-top: 8px;
    }
    .ant-collapse-header {
      position: relative;
      color: #393f4d;
      border: 1px solid #ff7a44;
      background-color: #fff;
      border-radius: 10px;
      > .anticon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .ant-collapse-item:last-child > .ant-collapse-content,
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 10px;
  }
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    font-weight: 300;
  }
}
