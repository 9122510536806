body {
  background: #fffaf5;
  font-family: Kanit;
  font-weight: 300;
  font-size: 16px;
  .layout {
    padding: 60px 0;
  }
  .container {
    margin: auto;
    max-width: 1200px;
    padding: 0 12px;
  }
  .icon {
    color: #ff7a44;
  }
  .wrap-dropdown-user-info {
    .wrap-user-info-header {
      padding: 10px 0;
      .wrap-image {
        margin: auto;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        overflow: hidden;
      }
      .name {
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        margin-top: 20px;
      }
    }
    .ant-dropdown-menu-item {
      border-top: 1px solid #ddd;
      font-weight: 300;
    }
    .button-logout {
      // border-bottom: 1px solid #ddd;
      button {
        display: block;
        width: 100%;
        text-align: left;
        background: none;
        outline: none;
        border: none;
        padding: 0;
      }
    }
  }
  button.with-icon {
    span {
      margin: 0 10px;
      vertical-align: middle;
    }
    svg {
      vertical-align: middle;
    }
  }
  .ant-form-item-has-error {
    .input-phone {
      border: 1px solid #ff4d4f;
      border-radius: 10px;
      .ant-input-group-wrapper {
        .ant-input-group-addon {
          background: #fff;
        }
      }
    }
  }
  .wrap-button-filter {
    display: flex;
    justify-content: center;
    button {
      margin: 0 10px;
    }
  }
}

.modal-close-incidents {
  .ant-btn  {
    border: 1px solid #ff7a44;
    color: #ff7a44;
  }
  .ant-btn-primary {
    background: linear-gradient(270deg, #ff967e 0%, #ff7a44 100%);
    border: none;
    color: #fff;
  }
}
.wrap-incidents-cancle {
  display: block;
  margin-bottom: 16px;
  padding-top: 16px;
  .wrap-input {
    margin-bottom: 16px;
  }
  .ant-select,
  .ant-input {
    width: 100%;
    display: block;
    margin-top: 8px;
  }
}
